@import url(https://fonts.cdnfonts.com/css/neue-haas-grotesk-text-pro);
@import url(https://fonts.cdnfonts.com/css/neue-haas-grotesk-text-pro);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ip-text-box {
    /*display: inline-block;*/
    position: relative;
    vertical-align: top;
    margin-top: 10px;
    margin-left: 10px;
}

.ip-text-box-label {
    position: relative;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.ip-text-box-text {
    position: relative;
    top: 10px;
    /*right: 185px;*/
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.lg-table {
    /*display: inline-block;*/
    width: 900px;
    margin-top: 10px;
    margin-right: 10px;
}

.lg-content {
    display: flex;
    flex-direction: row;
}

.table-header {
    background-color: black;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    padding: 10px;
}

.table-cell {
    background-color: #D8DADA;
    color: black;
    font-size: 15px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    padding: 10px;
    /*width: 800px;*/

}

.loading {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px;
}


.sign-out {
    display: inline-block;
    width: 160px;
    position: absolute;
    right: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    padding-right: 15px;
    
}

.vz-image {
    width: 10%;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.app-item {
    /*position: relative;*/
    /*margin-left: 0px;
    margin-right: 0px;*/
    min-height: 700px;
    width: 100%;
    /*padding: 1em auto;*/
    /*border-left: 1px solid #D8DADA;
    border-right: 1px solid #D8DADA;*/
    border-bottom: 1px solid #D8DADA;
    background-color: #FFF
}

.tab-panel {
    /*
    padding-left: 20px;
    padding-right: 50px;
    padding-bottom: 30px;
    padding-top: 25px;
    */
    margin-left: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #D8DADA;
    border-right: 1px solid #D8DADA;
    border-left: 1px solid #D8DADA;
}

.tab-content {
    padding-left: 20px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.content-item {
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.host-override-input {
    padding-right: 10px;
}

.host-override-unit {
    padding-bottom: 10px;
}

.custom-select {
    position: relative;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}

select {
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 15px;
}

.top {
    border-bottom: 1px solid #D8DADA;
    /*
    margin-right: auto;
    margin-left: auto;*/
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
}

.top-banner {
    display: flex;
    flex-direction: row;
}

.container {
    /*margin-right: auto;
    margin-left: auto;*/
    width: 100%;
    /*margin-bottom: 8em;*/
}

/*
.tab-panel {
    
    border-right-style: solid;
    border-left-style: solid;
    border-top-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    
}*/

.button {
    border: none;
    padding: 12px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: white;
    background-color: black;
    border-radius: 18px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}

.refresh {
    padding: 8px;
}

.textbox {
    height: 30px;
    font-size: 16px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}


.addremove {
    width: 30px;
    padding: 5px;
}

.section-heading {
    font-size: 25px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-weight: bold;
    border-bottom: 1px solid #D8DADA;
    padding-left: 20px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-top: 25px;
}

:root {
    --amplify-primary-color: white;
    --amplify-primary-contrast: black;
}



.selected-tab {
    background: #fff;
    border-color: #fff;
    color: #cd040b;
    border-radius: 5px 5px 0 0;
}

.tab-list {
    border-bottom: 1px solid white;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    padding: 0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}

.tab:hover {
    color: #cd040b;
}

.tab {
    display: inline-block;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: currentColor;
    border-bottom-color: initial;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom-width: initial;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    list-style-position: outside;
    list-style-position: initial;
    list-style-image: none;
    list-style-image: initial;
    list-style-type: none;
    padding: 6px 12px;
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 0px;
    cursor: pointer;
}
