@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-text-pro');

.ip-text-box {
    /*display: inline-block;*/
    position: relative;
    vertical-align: top;
    margin-top: 10px;
    margin-left: 10px;
}

.ip-text-box-label {
    position: relative;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.ip-text-box-text {
    position: relative;
    top: 10px;
    /*right: 185px;*/
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.lg-table {
    /*display: inline-block;*/
    width: 900px;
    margin-top: 10px;
    margin-right: 10px;
}

.lg-content {
    display: flex;
    flex-direction: row;
}

.table-header {
    background-color: black;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    padding: 10px;
}

.table-cell {
    background-color: #D8DADA;
    color: black;
    font-size: 15px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    padding: 10px;
    /*width: 800px;*/

}

.loading {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px;
}


.sign-out {
    display: inline-block;
    width: 160px;
    position: absolute;
    right: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    padding-right: 15px;
    
}

.vz-image {
    width: 10%;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.app-item {
    /*position: relative;*/
    /*margin-left: 0px;
    margin-right: 0px;*/
    min-height: 700px;
    width: 100%;
    /*padding: 1em auto;*/
    /*border-left: 1px solid #D8DADA;
    border-right: 1px solid #D8DADA;*/
    border-bottom: 1px solid #D8DADA;
    background-color: #FFF
}

.tab-panel {
    /*
    padding-left: 20px;
    padding-right: 50px;
    padding-bottom: 30px;
    padding-top: 25px;
    */
    margin-left: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #D8DADA;
    border-right: 1px solid #D8DADA;
    border-left: 1px solid #D8DADA;
}

.tab-content {
    padding-left: 20px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.content-item {
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.host-override-input {
    padding-right: 10px;
}

.host-override-unit {
    padding-bottom: 10px;
}

.custom-select {
    position: relative;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}

select {
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-size: 15px;
}

.top {
    border-bottom: 1px solid #D8DADA;
    /*
    margin-right: auto;
    margin-left: auto;*/
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
}

.top-banner {
    display: flex;
    flex-direction: row;
}

.container {
    /*margin-right: auto;
    margin-left: auto;*/
    width: 100%;
    /*margin-bottom: 8em;*/
}

/*
.tab-panel {
    
    border-right-style: solid;
    border-left-style: solid;
    border-top-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    
}*/

.button {
    border: none;
    padding: 12px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: white;
    background-color: black;
    border-radius: 18px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}

.refresh {
    padding: 8px;
}

.textbox {
    height: 30px;
    font-size: 16px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}


.addremove {
    width: 30px;
    padding: 5px;
}

.section-heading {
    font-size: 25px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
    font-weight: bold;
    border-bottom: 1px solid #D8DADA;
    padding-left: 20px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-top: 25px;
}

:root {
    --amplify-primary-color: white;
    --amplify-primary-contrast: black;
}


