@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-text-pro');

.selected-tab {
    background: #fff;
    border-color: #fff;
    color: #cd040b;
    border-radius: 5px 5px 0 0;
}

.tab-list {
    border-bottom: 1px solid white;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    padding: 0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
}

.tab:hover {
    color: #cd040b;
}

.tab {
    display: inline-block;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: initial;
    border-bottom-style: none;
    border-bottom-width: initial;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
    padding: 6px 12px;
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 0px;
    cursor: pointer;
}